import React, { createContext, useReducer } from "react"

const initialState = {
  loading: true,
  language: "pl",
  ageConfirmed: true,
  cookiesConfirmed: true,
  isSubmenuOpen: false,
  globalProducts: [],
  searchValue: "",
  globalTag: "all-products"
}

export const GlobalStateContext = createContext(initialState)
export const GlobalDispatchContext = createContext()

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_LANGUAGE": {
      return {
        ...state,
        language: action.payload
      }
    }
    case "CHANGE_LOADING": {
      return {
        ...state,
        loading: action.payload
      }
    }
    case "CONFIRM_AGE": {
      return {
        ...state,
        ageConfirmed: action.payload
      }
    }
    case "CONFIRM_COOKIES": {
      return {
        ...state,
        cookiesConfirmed: action.payload
      }
    }
    case "TOGGLE_SUBMENU": {
      return {
        ...state,
        isSubmenuOpen: action.payload
      }
    }
    case "SET_GLOBAL_PRODUCTS": {
      return {
        ...state,
        globalProducts: action.payload
      }
    }
    case "SET_GLOBAL_TAG": {
      return {
        ...state,
        globalTag: action.payload
      }
    }
    case "SET_SEARCH_VALUE": {
      return {
        ...state,
        searchValue: action.payload
      }
    }
    case "ADD_DATA_INTO_LOCAL_STORAGE": {
      localStorage.setItem("moonLarkAgeVerified", true)
      return {
        ...state,
        ageConfirmed: true
      }
    }
    case "ADD_COOKIES_DATA_INTO_LOCAL_STORAGE": {
      localStorage.setItem("moonLarkCookiesConfirmed", true)
      return {
        ...state,
        cookiesConfirmed: true
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
