module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://moonlark-backup.pl/graphql","baseUrl":"https://moonlark-backup.pl/","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Browar Moon Lark","short_name":"Moon Lark","start_url":"/","background_color":"#a983bb","theme_color":"#a983bb","display":"standalone","icon":"src/images/MoonLark_logo.jpeg","description":"Browar Rzemieślniczy Moon Lark to najwyższej jakości IPA, piwa kwaśne oraz stouty i portery. Znajdziesz u nas: piwo kraftowe, hard seltzer i miody.","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"0f9bd7de555aaf9f9a645646eda39d0a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GBHYJ746M8"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
